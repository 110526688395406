import Chart from 'chart.js'

const oilComparisonData = [
  {
    name: 'Palm oil',
    amount: '73'
  },
  {
    name: 'Soybean oil',
    amount: '56'
  },
  {
    name: 'Rape oil',
    amount: '25'
  },
  {
    name: 'Sunflower oil',
    amount: '18'
  },
  {
    name: 'Palm kernel oil',
    amount: '8'
  },
  {
    name: 'Cottonseed oil',
    amount: '5'
  },
  {
    name: 'Maize-germ oil',
    amount: '4'
  },
  {
    name: 'Peanut oil',
    amount: '4'
  },
  {
    name: 'Olive oil',
    amount: '3'
  },
  {
    name: 'Coconut oil',
    amount: '3'
  }
]

const oilname = oilComparisonData.map(item => item.name)
const oiltons = oilComparisonData.map(item => item.amount)

const oilComparisonConfig = {
  type: 'pie',
  data: {
    labels: oilname,
    datasets: [
      {
        backgroundColor: [
          '#EC9864',
          '#A4B881',
          '#9CB079',
          '#95A972',
          '#8DA16A',
          '#859962',
          '#7E925B',
          '#768A53',
          '#6E824B',
          '#677B44'
        ],
        data: oiltons
      }
    ]
  },
  options: {
    legend: {
      position: 'bottom'
    },
    responsive: true
  }
}

window.addEventListener('load', function loadOilComparisonChart() {
  const element = document.getElementById('chart-oil-comparison-en')
  if (element) {
    const context = element.getContext('2d')
    window.chartOilComparison = new Chart(context, oilComparisonConfig)
  }
})
