import Chart from 'chart.js'

const oilComparisonData = [
  {
    name: 'Huile de palme',
    amount: '3.8'
  },
  {
    name: 'Huile de colza',
    amount: '0.8'
  },
  {
    name: 'Huile de tournesol',
    amount: '0.8'
  },
  {
    name: 'Huile de soja',
    amount: '0.5'
  }
]

const oilname = oilComparisonData.map(item => item.name)
const oiltons = oilComparisonData.map(item => item.amount)

const oilComparisonConfig = {
  type: 'bar',
  data: {
    labels: oilname,
    datasets: [
      {
        backgroundColor: [
          '#EC9864',
          '#A4B881',
          '#9CB079',
          '#95A972',
          '#8DA16A',
          '#859962',
          '#7E925B',
          '#768A53',
          '#6E824B',
          '#677B44'
        ],
        data: oiltons
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    responsive: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      xPadding: 10,
      yPadding: 10,
      displayColors: false,
      backgroundColor: '#3A4E22',
      titleFontColor: '#FFFFFF',
      bodyFontColor: '#FFFFFF',
      callbacks: {
        title(tooltipItems) {
          return tooltipItems[0].label
        },
        label(tooltipItems) {
          return `${tooltipItems.yLabel} tonnes par hectare`
        }
      }
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Jahr'
          },
          ticks: {
            fontSize: '12'
          },
          gridLines: {
            drawBorder: false,
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Tonnes par hectare'
          },
          ticks: {
            fontSize: '12',
            beginAtZero: true
          },
          gridLines: {
            drawBorder: false
          }
        }
      ]
    }
  }
}

window.addEventListener('load', function loadOilComparisonChart() {
  const element = document.getElementById('chart-efficiency-fr')
  if (element) {
    const context = element.getContext('2d')
    window.chartOilComparison = new Chart(context, oilComparisonConfig)
  }
})
