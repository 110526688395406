import Chart from 'chart.js'

const productionWorldwideData = [
  {
    date: '2019',
    amount: '75'
  },
  {
    date: '2018',
    amount: '72.8'
  },
  {
    date: '2017',
    amount: '70.5'
  },
  {
    date: '2016',
    amount: '65.3'
  },
  {
    date: '2015',
    amount: '58.9'
  },
  {
    date: '2014',
    amount: '61.7'
  },
  {
    date: '2013',
    amount: '59.3'
  },
  {
    date: '2012',
    amount: '56.4'
  },
  {
    date: '2011',
    amount: '49.7'
  },
  {
    date: '2010',
    amount: '45.8'
  },
  {
    date: '2009',
    amount: '45.1'
  },
  {
    date: '2008',
    amount: '48.3'
  },
  {
    date: '2007',
    amount: '38.8'
  },
  {
    date: '2006',
    amount: '37.3'
  },
  {
    date: '2005',
    amount: '34'
  },
  {
    date: '2004',
    amount: '31.2'
  },
  {
    date: '2003',
    amount: '28.3'
  },
  {
    date: '2002',
    amount: '25.4'
  },
  {
    date: '2001',
    amount: '24'
  }
]

productionWorldwideData.reverse()

const years = productionWorldwideData.map(item => item.date)
const tons = productionWorldwideData.map(item => item.amount)

const productionWorldwideConfig = {
  type: 'line',
  data: {
    labels: years,
    datasets: [
      {
        fill: true,
        borderColor: '#EC9864',
        backgroundColor: '#E7C6B1',
        borderCapStyle: 'butt',
        borderWidth: 4,
        pointRadius: 2,
        pointHitRadius: 10,
        pointHoverRadius: 4,
        hoverBorderColor: '#F58229',
        pointHoverBackgroundColor: '#FFFFFF',
        pointHoverBorderColor: '#FFFFFF',
        lineTension: 0.5,
        data: tons
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    responsive: true,
    tooltips: {
      enabled: true,
      mode: 'single',
      xPadding: 10,
      yPadding: 10,
      displayColors: false,
      backgroundColor: '#F58229',
      titleFontColor: '#FFFFFF',
      bodyFontColor: '#FFFFFF',
      callbacks: {
        title(tooltipItems) {
          return tooltipItems[0].label
        },
        label(tooltipItems) {
          return `${tooltipItems.yLabel} Millionen Tonnen`
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: false,
            labelString: 'Jahr'
          },
          ticks: {
            fontSize: '12'
          },
          gridLines: {
            drawBorder: false,
            drawOnChartArea: false
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Millionen Tonnen'
          },
          ticks: {
            fontSize: '12',
            beginAtZero: true
          },
          gridLines: {
            drawBorder: false
          }
        }
      ]
    }
  }
}

window.addEventListener('load', function loadProductionWorldwideChart() {
  const element = document.getElementById('chart-production-worldwide')
  if (element) {
    const context = element.getContext('2d')
    window.chartProductionWorldwide = new Chart(context, productionWorldwideConfig)
  }
})
