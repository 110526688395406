import Chart from 'chart.js'

const oilComparisonData = [
  {
    name: 'Palmöl',
    amount: '73'
  },
  {
    name: 'Sojaöl',
    amount: '56'
  },
  {
    name: 'Rapsöl',
    amount: '25'
  },
  {
    name: 'Sonnenblumenöl',
    amount: '18'
  },
  {
    name: 'Palmkernöl',
    amount: '8'
  },
  {
    name: 'Baumwollsamenöl',
    amount: '5'
  },
  {
    name: 'Maiskeimöl',
    amount: '4'
  },
  {
    name: 'Erdnussöl',
    amount: '4'
  },
  {
    name: 'Olivenöl',
    amount: '3'
  },
  {
    name: 'Kokusnussöl',
    amount: '3'
  }
]

const oilname = oilComparisonData.map(item => item.name)
const oiltons = oilComparisonData.map(item => item.amount)

const oilComparisonConfig = {
  type: 'pie',
  data: {
    labels: oilname,
    datasets: [
      {
        backgroundColor: [
          '#EC9864',
          '#A4B881',
          '#9CB079',
          '#95A972',
          '#8DA16A',
          '#859962',
          '#7E925B',
          '#768A53',
          '#6E824B',
          '#677B44'
        ],
        data: oiltons
      }
    ]
  },
  options: {
    legend: {
      position: 'bottom'
    },
    responsive: true,
    maintainAspectRatio: false
  }
}

window.addEventListener('load', function loadOilComparisonChart() {
  const element = document.getElementById('chart-oil-comparison')
  if (element) {
    const context = element.getContext('2d')
    window.chartOilComparison = new Chart(context, oilComparisonConfig)
  }
})
